import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import Alert from 'components/ui-components-v2/Alert';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const borderWidth: InputAttribute<SliderSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.borderWidth', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.borderWidth', 'template-designer'),
    attribute: 'borderWidth',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: 0,
            max: 20,
            step: 1,
            hideMarks: false,
            allowZero: true,
            inputFieldDisplay: true,
            marks: true,
            endAdornment: 'px',
            defaultValue: 0
        },
        select: {
            ...baseSettings,
            type: 'select',
            sortOptions: true,
            options: [
                {
                    key: '0',
                    value: '0'
                },
                {
                    key: '1',
                    value: '1'
                },
                {
                    key: '2',
                    value: '2'
                },
                {
                    key: '3',
                    value: '3'
                }
            ]
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            sortOptions: true,
            options: [
                {
                    key: '0',
                    value: '0'
                },
                {
                    key: '1',
                    value: '1'
                },
                {
                    key: '2',
                    value: '2'
                },
                {
                    key: '3',
                    value: '3'
                }
            ]
        }
    },
    defaultSetting: 'slider',
    editForm: () => (
        <>
            <Alert severity="info" className="template-designer__dynamic-layer-edit__border-width-alert">
                {Translation.get('dynamicLayers.dynamicLayerEdit.warnings.borderWidth', 'template-designer')}
            </Alert>
            <NumberSettingsForm unit="px" />
            <MultiSelectFormDynamic
                unit="px"
                inputType="number"
                attribute={borderWidth.attribute}
                updateSettings={borderWidth.updateSettings}
                disabledIndex={0}
            />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { borderWidth };
