import React from 'react';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import Translation from 'components/data/Translation';
import { RadioListSettings, SelectSettings, SliderSettings } from 'components/template-designer/types/dynamicLayer.type';
import { DynamicLayerHelpers } from 'components/template-designer/helpers/dynamic-layer.helpers';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';
import { baseSettings } from './base-settings';
import { NumberSettingsForm } from '../forms/number-settings-form';

const borderRadiusOptions = [
    {
        key: '0',
        value: '0'
    },
    {
        key: '2',
        value: '2'
    },
    {
        key: '4',
        value: '4'
    },
    {
        key: '6',
        value: '6'
    },
    {
        key: '8',
        value: '8'
    },
    {
        key: '10',
        value: '10'
    },
    {
        key: '12',
        value: '12'
    },
    {
        key: '14',
        value: '14'
    },
    {
        key: '16',
        value: '16'
    }
];

const borderRadius: InputAttribute<SliderSettings | SelectSettings | RadioListSettings> = {
    label: Translation.get('dynamicLayers.labels.borderRadius', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.borderRadius', 'template-designer'),
    attribute: 'borderRadius',
    settings: {
        slider: {
            ...baseSettings,
            type: 'slider',
            min: 0,
            max: 16,
            step: 1,
            allowZero: true,
            inputFieldDisplay: true,
            marks: false,
            endAdornment: 'px',
            defaultValue: 0
        },
        select: {
            ...baseSettings,
            type: 'select',
            sortOptions: true,
            options: borderRadiusOptions
        },
        radioList: {
            ...baseSettings,
            type: 'radioList',
            sortOptions: true,
            options: borderRadiusOptions
        }
    },
    defaultSetting: 'slider',
    editForm: () => (
        <>
            <NumberSettingsForm unit="px" />
            <MultiSelectFormDynamic
                unit="px"
                inputType="number"
                attribute={borderRadius.attribute}
                updateSettings={borderRadius.updateSettings}
                disabledIndex={0}
            />
        </>
    ),
    updateSettings: (selectedLayer, attribute, settings) => DynamicLayerHelpers.updatSettingsMultiSelectNumber(selectedLayer, attribute, settings)
};

export { borderRadius };
